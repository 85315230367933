import React from 'react';

import { token } from '@atlaskit/tokens';

import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';

export const CalendarIcon = ({ isSelected }: { isSelected?: boolean }) => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	return (
		<svg
			width="25"
			height="25"
			viewBox="0 0 25 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			data-vc="calendar-icon"
			{...ssrPlaceholderIdProp}
		>
			<path
				d="M15.0234 2.29985C15.5363 2.29985 15.9589 2.68587 16.0167 3.18321L16.0234 3.29985V4.29985H18.0234C19.0778 4.29985 19.9416 5.1157 20.0179 6.15056L20.0234 6.29985V18.2999C20.0234 19.4044 19.128 20.2999 18.0234 20.2999H6.02344C4.91887 20.2999 4.02344 19.4044 4.02344 18.2999V6.29985C4.02344 5.19528 4.91887 4.29985 6.02344 4.29985H8.02344V3.29985C8.02344 2.74757 8.47115 2.29985 9.02344 2.29985C9.53627 2.29985 9.95894 2.68587 10.0167 3.18321L10.0234 3.29985V4.29985H14.0234V3.29985C14.0234 2.74757 14.4712 2.29985 15.0234 2.29985ZM18.0234 11.2999H6.02344V18.2999H18.0234V11.2999ZM8.02344 6.29985H6.02344V9.29985H18.0234V6.29985H16.0234V7.29985C16.0234 7.85214 15.5757 8.29985 15.0234 8.29985C14.5106 8.29985 14.0879 7.91383 14.0302 7.41649L14.0234 7.29985V6.29985H10.0234V7.29985C10.0234 7.85214 9.57572 8.29985 9.02344 8.29985C8.5106 8.29985 8.08793 7.91383 8.03017 7.41649L8.02344 7.29985V6.29985Z"
				fill={isSelected ? token('color.icon.selected', '#0c66e4') : token('color.icon', '#42526E')}
			/>
		</svg>
	);
};
